<template>
  <v-progress-linear indeterminate v-if="!inited" color="primary"></v-progress-linear>
  <router-view v-if="inited" />
  <CookieBanner />
</template>

<script>
import { useAppStateStore } from './stores/AppStateStore'
import { storeToRefs } from 'pinia'
import CookieBanner from './components/CookieBanner.vue'

export default {
  components: {CookieBanner},
  setup() {
    const appStateStore = useAppStateStore()
    appStateStore.init()

    const {inited, user} = storeToRefs(appStateStore)

    return {inited, user}
  }
}
</script>

<style>
#app {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100dvh;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.text-pre-line {
   white-space: pre-line
}

.rounded-12 {
  border-radius: 12px !important;
}
.rounded-16 {
  border-radius: 16px !important;
}
.exie-text-h1 {
  letter-spacing: 0;
  font-size: 88px;
  line-height: 96px;
  font-weight: 500;
}
.exie-text-h2 {
  letter-spacing: 0;
  font-size: 57px;
  line-height: 60px;
  font-weight: 400;
}
.exie-text-h3 {
  letter-spacing: 0;
  font-size: 48px;
  line-height: 50px;
  font-weight: 400;
}
.exie-text-h4 {
  letter-spacing: 0;
  font-size: 34px;
  line-height: 40px;
  font-weight: 400;
}
.exie-text-h5 {
  letter-spacing: 0;
  font-size: 23px;
  line-height: 32px;
  font-weight: 400;
}
.exie-text-h6 {
  letter-spacing: 0;
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
}
.exie-text-subtitle-1 {
  letter-spacing: 0;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
}
.exie-text-subtitle-2 {
  letter-spacing: 0;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
}
.exie-text-body-1 {
  letter-spacing: 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}
.exie-text-body-2 {
  letter-spacing: 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}
.exie-text-button {
  letter-spacing: 0;
  font-size: 15px;
  line-height: 36px;
  font-weight: 500;
}
.exie-text-caption {
  letter-spacing: .4px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}
.exie-text-overline {
  letter-spacing: 2px;
  font-size: 12px;
  line-height: 32px;
  font-weight: 400;
}
.v-btn {
  letter-spacing: 0.5px !important;
}
</style>
