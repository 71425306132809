const getFullDate = (unixTimestamp) => {
    const locale = navigator.language
    return new Date(unixTimestamp * 1000).toLocaleDateString(locale, {
        year: 'numeric', month: 'short', day: 'numeric'
    })
    
}

const getFullDateFromString = (dateString) => {
    const locale = navigator.language
    const parsedDate = Date.parse(dateString)
    return new Date(parsedDate).toLocaleDateString(locale, {
        year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric'
    })
}

const formatToShortDate = (dateString) => {
    const locale = navigator.language
    const parsedDate = Date.parse(dateString)
    return new Date(parsedDate).toLocaleString(locale)
}

const currencyFormat = (amount) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    })

    return formatter.format(amount)
}

export {getFullDate, currencyFormat, formatToShortDate, getFullDateFromString}