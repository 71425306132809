import { defineStore } from 'pinia'
import api from '@/utils/api'


export const useAppStateStore = defineStore('appStateStore', {
    state: () => ({ 
        inited: false,
        user: null
    }),
    actions: {
        async init() {
            if(this.inited) {
                return
            }
            await this.loadUser()
            this.inited = true
        },
        async loadUser() {
            const response = await api.get('/api/init/whoami')

            if(response.user) {
                this.user = response.user
            }
        },
        async logout() {
            this.user = null
        }
    }
})