<template>
    <v-footer class="mt-5 mb-2">
        <v-row>
            <v-col cols="12" sm="4" class="text-center pa-0">
                <RouterLink to="/tos" class="text-decoration-none text-caption">Terms of Service</RouterLink>
            </v-col>
            <v-col cols="12" sm="4" class="pa-0">
                <p class="text-center text-caption">Mockin by Exie App LLC | {{ new Date().getFullYear() }}</p>
            </v-col>
            <v-col cols="12" sm="4" class="text-center pa-0">
                <RouterLink to="/privacy-policy" class="text-decoration-none text-caption">Privacy Policy</RouterLink>
            </v-col>
        </v-row>
    </v-footer>
</template>