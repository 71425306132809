<template>
    <section class="micro">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" width="15" height="15">
            <path fill="#ffffff" d="M176 352c53.02 0 96-42.98 96-96V96c0-53.02-42.98-96-96-96S80 42.98 80 96v160c0 53.02 42.98 96 96 96zm160-160h-16c-8.84 0-16 7.16-16 16v48c0 74.8-64.49 134.82-140.79 127.38C96.71 376.89 48 317.11 48 250.3V208c0-8.84-7.16-16-16-16H16c-8.84 0-16 7.16-16 16v40.16c0 89.64 63.97 169.55 152 181.69V464H96c-8.84 0-16 7.16-16 16v16c0 8.84 7.16 16 16 16h160c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16h-56v-33.77C285.71 418.47 352 344.9 352 256v-48c0-8.84-7.16-16-16-16z"></path>
        </svg>
    </section>
    
</template>

<style scoped>
.micro {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: #000;
  border-radius: 50%;
}

.micro:before,
.micro:after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: solid 3px #000;
  border-radius: 50%;
}

.micro:before {
  animation: ripple 2s linear infinite;
}

.micro:after {
  animation: ripple 2s 1s linear infinite;
}

@keyframes ripple {
  to {
    transform: scale(2);
    opacity: 0;
  }
}

</style>