<template>

</template>
<script>
import { useAppStateStore } from '@/stores/AppStateStore'
import { storeToRefs } from 'pinia'
import { Centrifuge } from 'centrifuge'

export default {
    setup() {
        const appStateStore = useAppStateStore()
        const { user } = storeToRefs(appStateStore)

        if(!document.wsClient) {
            document.wsClient = new Centrifuge(process.env.VUE_APP_WEBSOCKET_URL, {
                token: user.value?.socket_token
            })
            document.wsClient.connect()
        }
        
    }
}
</script>