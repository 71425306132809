<template>
    
</template>

<script>
import { useAppStateStore } from '@/stores/AppStateStore'
import { useRouter } from 'vue-router'

export default {
    setup() {
        const appStateStore = useAppStateStore()
        const router = useRouter()

        if(!appStateStore.user) {
            router.push({name: 'login'})
        } else {
            router.push({name: 'dashboard'})
        }
    }
}
</script>