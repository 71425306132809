<template>
    <div class="mb-3"></div>
    <v-row justify="center">
        <v-col cols="12" sm="8" md="9">
            <p class="exie-text-h4">Hello, {{ user?.name.split(' ')[0] }}!</p>
            <p class="exie-text-h4">You have <span class="text-deep-orange-lighten-2">{{ user?.available_interviews }} available</span> interviews</p>
        </v-col>
        <v-col cols="12" sm="4" md="3">
            <v-btn @click="handleAddInterview" class="float-right rounded-12" color="black" variant="flat" v-if="user?.available_interviews == 0">Add Interview</v-btn>
        </v-col>
    </v-row>
    <v-row class="mt-10" v-if="activeInterviews.length > 0">
        <v-col cols="12" class="pb-0">
            <p class="exie-text-subtitle-1 font-weight-bold">Active Interviews</p>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-for="(interview, index) in activeInterviews" :key="index">
            <v-card class="px-5 py-7 rounded-16 h-100 bg-grey-lighten-4 d-flex flex-column" elevation="0">
                <p class="exie-text-h5">{{ getInterviewTitle(interview.type) }}</p>
                <p class="text-caption text-medium-emphasis mt-1 mb-7">{{ getLanguageTitle(interview.language) }} | {{ getFullDateFromString(interview.ts_created) }}</p>
                <p class="mt-auto float-end"><v-btn variant="flat" color="black" class="rounded-12" :to="{name: 'interview', params: {id: interview.id}}">Continue</v-btn></p>
            </v-card>
        </v-col>
    </v-row>
    <v-row class="mt-10" v-if="user.available_interviews > 0">
        <v-col cols="12">
            <p class="exie-text-subtitle-1 font-weight-bold mb-3">Interview Language</p>
            <v-select
                rounded="lg"
                variant="outlined"
                :items="languages"
                v-model="language"
                @update:model-value="handleUpdateLanguage"
            ></v-select>
        </v-col>
        <v-col cols="12" class="pb-1">
            <p class="exie-text-h5">Choose an interview to get started:</p>
        </v-col>
    </v-row>
    <v-row class="" v-if="user.available_interviews > 0">
        <v-col cols="12" sm="6" md="4" lg="3" v-for="(interview, index) in interviews" :key="index" v-show="interview.show == true && ((interview.test != true) || [1,4,5].includes(user.id))">
            <v-card class="px-5 py-7 rounded-16 h-100 d-flex justify-center align-center cursor-pointer bg-grey-lighten-4" elevation="0" @click="handleCreateInterview(interview.value)">
                <p class="exie-text-body-1 text-center">{{ interview.title }}</p>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { useAppStateStore } from '@/stores/AppStateStore'
import api from '@/utils/api'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import { interviews, getInterviewTitle, languages, getLanguageTitle } from '@/utils/interviews'
import { ref } from 'vue'
import { getFullDateFromString } from '@/utils/dataFormatter'

export default {
    setup() {
        const router = useRouter()
        const appStateStore = useAppStateStore()

        const {user} = storeToRefs(appStateStore)

        appStateStore.loadUser()

        const createingInterview = ref(false)

        const language = ref(window.localStorage.getItem('interview_language') || 'english')

        const activeInterviews = ref([])

        const handleAddInterview = async () => {
            window.open('https://forms.gle/haxb4JU7fsFZB5wj6', '_blank')
        }

        const handleCreateInterview = async (type) => {
            if(createingInterview.value) {
                return
            }
            createingInterview.value = true
            const response = await api.post('/api/interview/create', {
                type: type,
                language: language.value
            })

            if(response.interview) {
                router.push({name: 'interview', params: {id: response.interview.id}})
            }

            createingInterview.value = false
        }

        const getActiveInterviews = async () => {
            const response = await api.get('/api/interview/list/active')

            if(response.interviews) {
                activeInterviews.value = response.interviews
            }
        }

        const handleUpdateLanguage = () => {
            window.localStorage.setItem('interview_language', language.value)
        }

        getActiveInterviews()

        return {user, interviews, handleCreateInterview, language, activeInterviews, getInterviewTitle, getFullDateFromString, languages, getLanguageTitle, handleUpdateLanguage, handleAddInterview}
    }
}
</script>