import { createRouter, createWebHistory } from 'vue-router'

import IndexView from '@/views/IndexView.vue'
import LoginView from '@/views/LoginView.vue'
import AuthorizedLayout from '@/layouts/AuthorizedLayout.vue'
import DashboardView from '@/views/DashboardView.vue'
import HistoryView from '@/views/HistoryView.vue'
import IntreviewView from '@/views/InterviewView.vue'
import PrivacyPolicyView from '@/views/PivacyPolicyView.vue'
import TosView from '@/views/TosView.vue'


const routes = [
  {
    path: '/',
    name: 'index',
    component: AuthorizedLayout,
    children: [
      {
        path: '',
        name: 'index',
        component: IndexView
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: DashboardView,
        meta: {
          navbar: 'dashboard'
        }
      },
      {
        path: 'history',
        name: 'history',
        component: HistoryView,
        meta: {
          navbar: 'history'
        }
      },
      {
        path: 'interview/:id([a-zA-Z0-9-]+)',
        name: 'interview',
        component: IntreviewView
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicyView
  },
  {
    path: '/tos',
    name: 'tos',
    component: TosView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
