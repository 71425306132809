<template>
    <div id="bars">
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
    </div>
</template>

<style scoped>
    #bars {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
/*     background: black; */
}

.bar {
    background: #000;
    bottom: 1px;
    height: 3px;
    width: 3px;
    margin: 0px 1px;
    border-radius: 5px;
    animation: sound 0ms -600ms linear infinite alternate;
}

@keyframes sound {
    0% {
       opacity: .35;
        height: 3px; 
    }
    100% {
        opacity: 1;       
        height: 20px;        
    }
}

.bar:nth-child(1)  { left: 1px; animation-duration: 474ms; }
.bar:nth-child(2)  { left: 4px; animation-duration: 433ms; }
.bar:nth-child(3)  { left: 7px; animation-duration: 407ms; }
.bar:nth-child(4)  { left: 10px; animation-duration: 458ms; }
.bar:nth-child(5)  { left: 13px; animation-duration: 400ms; }
.bar:nth-child(6)  { left: 17px; animation-duration: 427ms; }
.bar:nth-child(7)  { left: 20px; animation-duration: 441ms; }
.bar:nth-child(8)  { left: 23px; animation-duration: 419ms; }
.bar:nth-child(9)  { left: 27px; animation-duration: 487ms; }
.bar:nth-child(10) { left: 30px; animation-duration: 442ms; }
</style>